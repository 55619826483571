<template>
  <div>
    <v-dialog
      persistent
      v-model="openDialogDetails"
      width="1100"
      style="z-index: 1"
    >
      <v-card style="border-radius: 10px; padding: 20px !important">
        <v-icon @click="close" class="dp-icon-modal">mdi-close</v-icon>
        <v-container>
          <!-- title content -->
          <v-row>
            <v-col>
              <p class="dp-title-modal" style="text-align: center">
                Detalle de cotización
                <span
                  :class="`status-order-span status-order-background-color-${statusOrder}`"
                  >{{ statusOrder }}</span
                >
              </p>
            </v-col>
          </v-row>
          <hr style="height: 1px; background-color: #ffce00; border: none" />
          <!-- description content -->
          <v-row>
            <v-col cols="12" sm="6">
              <div>
                <!-- start item id -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p
                      style="
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        margin-bottom: 0;
                      "
                    >
                      ID
                    </p>
                  </div>
                  <div class="status-content-restant">
                    <p
                      style="
                        color: #b7b7b7;
                        font-family: 'Poppins', sans-serif;
                        margin-bottom: 0;
                      "
                    >
                      {{ idOrder }}
                    </p>
                  </div>
                </div>
                <!-- end item id -->
                <!-- start item proyecto -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p
                      style="
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        margin-bottom: 0;
                      "
                    >
                      Proyecto
                    </p>
                  </div>
                  <div class="status-content-restant">
                    <p
                      style="
                        color: #b7b7b7;
                        font-family: 'Poppins', sans-serif;
                        margin-bottom: 0;
                      "
                    >
                      {{ proyecto }}
                    </p>
                  </div>
                </div>
                <!-- end item proyecto -->
                <!-- start item categoria -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p
                      style="
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        margin-bottom: 0;
                      "
                    >
                      Categoría
                    </p>
                  </div>
                  <div class="status-content-restant">
                    <p
                      style="
                        color: #b7b7b7;
                        font-family: 'Poppins', sans-serif;
                        margin-bottom: 0;
                      "
                    >
                      {{ categoria }}
                    </p>
                  </div>
                </div>
                <!-- end item categoria -->
                <!-- start item proveedor -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p
                      style="
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        margin-bottom: 0;
                      "
                    >
                      Proveedor
                    </p>
                  </div>
                  <div class="status-content-restant">
                    <p
                      style="
                        color: #b7b7b7;
                        font-family: 'Poppins', sans-serif;
                        margin-bottom: 0;
                      "
                    >
                      {{ proveedor }}
                    </p>
                  </div>
                </div>
                <!-- end item proveedor -->
                <!-- start item método de pago -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p
                      style="
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        margin-bottom: 0;
                      "
                    >
                      Método de pago
                    </p>
                  </div>
                  <div class="status-content-restant">
                    <p
                      style="
                        color: #b7b7b7;
                        font-family: 'Poppins', sans-serif;
                        margin-bottom: 0;
                      "
                    >
                      {{ metodoPago }}
                    </p>
                  </div>
                </div>
                <!-- end item proveedor -->
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <div>
                <!-- start item fecha de creacion -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p
                      style="
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        margin-bottom: 0;
                      "
                    >
                      Fecha de creación
                    </p>
                  </div>
                  <div class="status-content-restant">
                    <p
                      style="
                        color: #b7b7b7;
                        font-family: 'Poppins', sans-serif;
                        margin-bottom: 0;
                      "
                    >
                      {{ fechaCreacionOrder }}
                    </p>
                  </div>
                </div>
                <!-- end item fecha de creacion -->
                <!-- start item comentarios -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p
                      style="
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        margin-bottom: 0;
                      "
                    >
                      Notas al proveedor:
                    </p>
                  </div>
                  <div class="status-content-restant">
                    <p
                      style="
                        color: #b7b7b7;
                        font-family: 'Poppins', sans-serif;
                        margin-bottom: 0;
                      "
                    >
                      {{
                        comentarios.trim() === ""
                          ? '"No hay notas adicionales para esta orden de compra."'
                          : comentarios
                      }}
                    </p>
                  </div>
                </div>
                <!-- end item comentarios -->
                <!-- start item comentarios -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p
                      style="
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        margin-bottom: 0;
                      "
                    >
                                            Notas internas
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on"> -->
                          <v-icon
                            style="
                              cursor: pointer;
                              font-size: 15px;
                              margin-bottom: 10px;
                              margin-left: 0px !important;
                            "
                            color="blue"
                            class="ml-2"
                            v-bind="attrs"
                            v-on="on"
                            >mdi-alert-circle</v-icon
                          >

                          <!-- </v-btn> -->
                        </template>
                        <span
                          >Estas notas no serán visibles por los proveedores ni
                          estarán presentes en la cotización generada.
                        </span>
                        <br />
                        <span
                          >Solo modificables en: Borrador y correcciones.</span
                        >
                      </v-tooltip>
                      :
                    </p>
                  </div>
                  <div class="status-content-restant">
                    <p
                      style="
                        color: #b7b7b7;
                        font-family: 'Poppins', sans-serif;
                        margin-bottom: 0;
                      "
                    >
                      {{
                        comentariosInternos.trim() === ""
                          ? '"No hay notas internas definidas para esta orden de compra."'
                          : comentariosInternos
                      }}
                    </p>
                  </div>
                </div>
                <!-- end item comentarios -->
              </div>
            </v-col>
          </v-row>
          <br />
          <!-- table materials content -->
          <v-row>
            <v-col cols="12" sm="12">
              <div>
                <!-- Start simple table -->
                <v-card style="border-radius: 20px; padding: 20px !important">
                  <v-simple-table fixed-header height="210px">
                    <template>
                      <thead v-if="!bLoading">
                        <tr>
                          <th
                            class="text-left"
                            style="border-bottom: 3px solid #e4e4e4"
                          >
                            Concepto
                          </th>
                          <th
                            class="text-left"
                            style="border-bottom: 3px solid #e4e4e4"
                          >
                            Cantidad
                          </th>
                          <th
                            class="text-left"
                            style="border-bottom: 3px solid #e4e4e4"
                          >
                            Unidad de medida
                          </th>
                          <th
                            class="text-left"
                            style="border-bottom: 3px solid #e4e4e4"
                          >
                            Precio unitario
                          </th>
                          <th
                            class="text-left"
                            style="border-bottom: 3px solid #e4e4e4"
                          >
                            Importe
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="!bLoading">
                        <tr
                          v-for="(item, index) in itemsRequestPerMaterial"
                          :key="index"
                        >
                          <td class="text-left">{{ item.sMaterialName }}</td>
                          <td class="text-left">{{ item.dQuantity }}</td>
                          <td class="text-left">{{ item.sMeasureUnitName }}</td>
                          <td class="text-left">
                            ${{ mask(item.dUnitPrice) }} MXN
                          </td>
                          <td class="text-left">
                            ${{ mask(item.dTotal) }} MXN
                          </td>
                        </tr>
                      </tbody>
                      <div v-show="bLoading">
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                        <div class="content-text-loading">
                          <p class="text-loading">
                            Cargando... Espere por favor
                          </p>
                        </div>
                      </div>
                    </template>
                  </v-simple-table>
                  <div
                    style="
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                      padding-right: 20px;
                      margin-top: 10px;
                    "
                  >
                    <p
                      style="
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                      "
                    >
                      SUBTOTAL
                      <span style="color: #63e3b2; margin-left: 10px"
                        >${{ mask(subtotal) }} MXN</span
                      >
                    </p>
                  </div>
                </v-card>
                <!-- End simple table -->
              </div>
            </v-col>
          </v-row>
          <br />
          <!-- button content -->
          <v-row>
            <v-col cols="12" sm="6">
              <div>
                <v-btn
                  x-large
                  color="black"
                  width="175"
                  outlined
                  class="text-Poppins"
                  style="
                    height: 50px;
                    font-size: 13px;
                    font-weight: 600;
                    border-radius: 5px;
                  "
                  :disabled="disableBtnDownloadDoc"
                  @click="downloadCotizacion"
                  :loading="loadingButtonCotizacion"
                  >Descargar cotización
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="6" class="text-right">
              <div>
                <v-btn
                  x-large
                  color="black"
                  width="175"
                  outlined
                  class="text-Poppins"
                  style="
                    height: 50px;
                    font-size: 13px;
                    font-weight: 600;
                    border-radius: 5px;
                  "
                  @click="rechazarOrden"
                  >Enviar a correción
                </v-btn>
                <v-btn
                  x-large
                  width="175"
                  color="primary"
                  class="text-Poppins"
                  style="
                    height: 50px;
                    color: black;
                    box-shadow: 0px 20px 40px #00000029;
                    border-radius: 5px;
                    font-size: 13px;
                    font-weight: 600;
                    margin-left: 25px;
                  "
                  @click="aceptarOrden"
                  :loading="loadingButtonAceptar"
                  >Aceptar
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "DetailsOCs",
  props: {
    openDialogDetails: {
      type: Boolean,
      default: false,
    },
    itemDetails: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      bLoading: true,
      itemsRequestPerMaterial: [],
      itemStatus: [],
      statusOrder: "",
      subtotal: 0,
      idOrder: "",
      comentarios: "",
      comentariosInternos: "",
      categoria: "",
      proyecto: "",
      fechaCreacionOrder: "",
      proveedor: "",
      metodoPago: "",
      loadingButtonCotizacion: false,
      loadingButtonAceptar: false,
      disableBtnDownloadDoc: false,
    };
  },
  methods: {
    rechazarOrden() {
      this.close();
      this.$emit("openDialogDenyOrderFunction", this.itemDetails);
    },
    close() {
      this.$emit("close");
      this.itemsRequestPerMaterial = [];
      this.bLoading = true;
    },
    allData() {
      this.getMaterialResquest();
      this.getStatus();
      this.disableBtnDownloadDoc = this.itemDetails.sQuoteKey == "";
      this.statusOrder = this.itemDetails.PurchaseOrderStatusRecords.sName;
      this.comentarios = this.itemDetails.sComments;
      this.comentariosInternos = this.itemDetails.sPrivateComments;
      this.proyecto = this.itemDetails.sProjectName;
      this.categoria = this.itemDetails.sCategoryName;
      this.proveedor = this.itemDetails.sSupplierName;
      this.metodoPago = this.itemDetails.sPaymentMethod;
      this.fechaCreacionOrder = this.itemDetails.tDate;
      this.idOrder = this.itemDetails.sPurchaseOrderId;
    },
    getStatus() {
      // get status
      db.get(`${uri}/api/v1/purchase_order_status`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          var arr = [];

          for (let i = 0; i < resp.data.orderStatus.length; i++) {
            const element = resp.data.orderStatus[i];
            if (element.sName == "Aprobada") {
              arr.push(element);
            }
          }
          this.itemStatus = arr[0];
        })
        .catch((err) => {
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    aceptarOrden() {
      this.loadingButtonAceptar = true;

      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
        },
      };
      const params = {
        sPurchaseOrderStatusId: this.itemStatus.sPurchaseOrderStatusId,
        sComments: "Sin comentarios",
      };
      db.put(
        `${uri}/api/v1/purchase_order/${this.itemDetails.sPurchaseOrderId}`,
        params,
        config
      )
        .then((resp) => {
          this.loadingButtonAceptar = false;
          this.close();
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.$store.commit("toggle_alert", alert);
          this.$store.commit("refresher", true);
        })
        .catch((err) => {
          this.loadingButtonAceptar = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    downloadCotizacion() {
      this.loadingButtonCotizacion = true;

      db.get(
        `${uri}/api/v1/purchase_order/order/${this.itemDetails.sPurchaseOrderId}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
        .then((resp) => {
          if (resp.data.order.sQuoteKey !== "Sin documento por el momento.") {
            this.loadingButtonCotizacion = false;
            window.open(resp.data.order.sQuoteKey, "_blank");
          } else {
            const alert = {
              color: "red",
              text: "No existe un documento asociado a esta orden de compra.",
            };
            this.$store.commit("toggle_alert", alert);
            this.loadingButtonCotizacion = false;
          }
        })
        .catch((err) => {
          this.loadingButtonCotizacion = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getMaterialResquest() {
      // get material per request
      db.get(
        `${uri}/api/v1/purchase_order/materials/${this.itemDetails.sPurchaseOrderId}/1`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
          params: {
            // iPageNumber: "1",
            formatDate: true,
          },
        }
      )
        .then((resp) => {
          this.itemsRequestPerMaterial = resp.data.OrderMaterials.map((e) => {
            return {
              ...e,
              dQuantity: Number(e.dQuantity),
            };
          });
          this.bLoading = false;

          this.sumatoriaMateriales();
        })
        .catch((err) => {
          this.bLoading = false;

          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    sumatoriaMateriales() {
      var sum = 0;
      for (let i = 0; i < this.itemsRequestPerMaterial.length; i++) {
        const element = this.itemsRequestPerMaterial[i];
        sum = sum + element.dQuantity * element.dUnitPrice;
      }
      this.subtotal = sum;
    },
    mask(price) {
      const nf = new Intl.NumberFormat("en-US");
      let number = nf.format(price);
      return price % 1 === 0 ? (number = number + ".00") : number;
    },
  },
  watch: {
    openDialogDetails: function () {
      if (this.openDialogDetails) {
        this.allData();
      }
    },
  },
  computed: {
    // picker detail dialog state
    bPickerDetails() {
      return this.$store.state.bPickerDetails;
    },
  },
};
</script>

<style scoped>
.status-order-span {
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  padding: 5px 15px;
  border-radius: 30px;
  position: absolute;
  margin-left: 5px;
  box-shadow: 0px 4px 8px #8d8d8d !important;
}

.status-content-flex {
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.status-content-fixed {
  width: 190px;
}

.status-content-restant {
  width: calc(100% - 190px);
}

.status-order-span-color-Enviada {
  color: #79c6ff;
}

.status-order-span-color-Borrador {
  color: #7e7e7e;
}

.status-order-span-color-Aprobada,
.status-order-span-color-Recibida {
  color: #97e6b6;
}

.status-order-span-color-Correcciones {
  color: #ff7d7d;
}

.status-order-span-color-Pagada {
  color: #ffce00;
}

.status-order-span-color-Cancelada {
  color: #ff7d7d;
}

.status-order-background-color-Enviada {
  background-color: #79c6ff;
}

.status-order-background-color-Borrador {
  background-color: #7e7e7e;
}

.status-order-background-color-Aprobada,
.status-order-background-color-Recibida {
  background-color: #97e6b6;
}

.status-order-background-color-Correcciones {
  background-color: #ff7d7d;
}

.status-order-background-color-Pagada {
  background-color: #ffce00;
}

.status-order-background-color-Cancelada {
  background-color: #ff7d7d;
}
</style>