<template>
  <div>
    <!-- Start card for float effect -->
    <v-card class="TableWrapper">
      <!-- Start simple table -->
      <v-simple-table fixed-header height="490px">
        <template v-slot:default>
          <thead v-if="!bLoading">
            <tr>
              <th class="text-left" style="border-bottom: 3px solid #e4e4e4">
                ID
              </th>
              <th class="text-left" style="border-bottom: 3px solid #e4e4e4">
                Proyecto
              </th>
              <th class="text-left" style="border-bottom: 3px solid #e4e4e4">
                Proveedor
              </th>
              <th class="text-left" style="border-bottom: 3px solid #e4e4e4">
                Método de Pago
              </th>
              <th class="text-left" style="border-bottom: 3px solid #e4e4e4">
                Monto
              </th>
              <th class="text-left" style="border-bottom: 3px solid #e4e4e4">
                Estatus
              </th>
              <th
                class="text-left"
                style="border-bottom: 3px solid #e4e4e4; width: 120px"
              >
                Acción
              </th>
            </tr>
          </thead>
          <tbody v-if="!bLoading">
            <tr v-for="(item, index) in items" :key="index">
              <td class="text-left">#{{ item.sPurchaseOrderId.slice(-5) }}</td>
              <td class="text-left">{{ item.sProjectName }}</td>
              <td class="text-left">{{ item.sSupplierName }}</td>
              <td class="text-left">{{ item.sPaymentMethod }}</td>
              <td class="text-left">${{ mask(item.dTotalPrice) }} MXN</td>
              <td style="text-align: left; color: #79c6ff; font-weight: bold">
                {{ item.PurchaseOrderStatusRecords.sName }}
              </td>
              <td>
                <div>
                  <v-btn
                    icon
                    color="black"
                    @click="getDetailOC(item.sPurchaseOrderId)"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <!-- <v-btn icon color="black" @click="viewDetails(item)">
                    <v-icon>mdi-yin-yang</v-icon>
                  </v-btn> -->
                  <v-btn icon color="#FF5757" @click="deleteOrder(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
          <div v-show="bLoading">
            <v-progress-linear
              indeterminate
              color="yellow darken-2"
            ></v-progress-linear>
            <div class="content-text-loading">
              <p class="text-loading">Cargando... Espere por favor</p>
            </div>
          </div>
        </template>
      </v-simple-table>
      <div class="footer-table">
        <div style="margin-right: 30px">
          <p>
            Filas por página:
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="footer-table-dropdown"
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ iItemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="changeTablePagination(10)">
                  <v-list-item-title>10</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeTablePagination(25)">
                  <v-list-item-title>25</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeTablePagination(50)">
                  <v-list-item-title>50</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeTablePagination(100)">
                  <v-list-item-title>100</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </p>
        </div>
        <div style="margin-right: 15px">
          <p>{{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}</p>
        </div>
        <div>
          <v-btn
            elevation="0"
            class="footer-table-buttons"
            @click="backPageContacts"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            elevation="0"
            class="footer-table-buttons"
            @click="nextPageContacts"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
      <!-- End simple table -->
      <!-- Start pagination -->

      <!-- End pagination -->
    </v-card>
    <!-- End card for float effect -->
    <DenyOrder
      @close="openDialogDenyOrder = false"
      :openDialogDenyOrder="openDialogDenyOrder"
      :itemDetailsDeny="itemDetailsDeny"
    ></DenyOrder>
    <ViewDetails
      @close="openDialogDetails = false"
      :openDialogDetails="openDialogDetails"
      @openDialogDenyOrderFunction="openDialogDenyOrderFunction"
      :itemDetails="itemDetails"
    ></ViewDetails>
  </div>
</template>
<script>
import ViewDetails from "@/components/views/OCs/Details";
import DenyOrder from "@/components/views/OCs/DenyOrder";

export default {
  components: {
    ViewDetails,
    DenyOrder,
  },
  data() {
    return {
      bLoading: false,
      // number of pages
      iCurrentPage: 1,
      iPageNumber: 1,

      // --- variables gestion orden de compra --- //
      items: [],
      itemStatus: [],
      iItemsPerPage: 50,
      numPages: 0,
      totalItems: 0,
      openDialogDetails: false,
      itemDetails: {},
      openDialogDenyOrder: false,
      itemDetailsDeny: {},
    };
  },
  mounted() {
    this.getStatus();
  },
  methods: {
    getStatus() {
      // get status
      db.get(`${uri}/api/v1/purchase_order_status`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          var arr = [];

          for (let i = 0; i < resp.data.orderStatus.length; i++) {
            const element = resp.data.orderStatus[i];
            if (element.sName == "Enviada") {
              arr.push(element);
            }
          }
          this.itemStatus = arr[0];
          this.getItems(arr[0]);
        })
        .catch((err) => {
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getItems(e) {
      this.bLoading = true;
      // get items
      db.get(`${uri}/api/v1/purchase_order`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
        params: {
          iPageNumber: this.iCurrentPage,
          sSearch: this.sSearch,
          iItemsPerPage: this.iItemsPerPage,
          sArea: this.sArea,
          sPurchaseOrderStatusId: e.sPurchaseOrderStatusId,
          tStart: this.startDate,
          tEnd: this.endDate,
        },
      })
        .then((resp) => {
          this.bLoading = false;
          this.numPages = resp.data.iNumPages;
          this.iPageNumber = resp.data.iNumPages;
          this.items = resp.data.orders;
          this.totalItems = resp.data.iTotal;
          this.$store.commit("refresher", false);
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    openDialogDenyOrderFunction(e) {
      this.openDialogDenyOrder = true;
      this.itemDetailsDeny = e;
    },
    //view details of orders
    viewDetails(e) {
      this.openDialogDetails = true;
      this.itemDetails = e;
    },
    getDetailOC(sOCId) {
      this.$router.push({
        name: "DetailsOCGestion",
        params: { sOCId: sOCId },
      });
    },
    deleteOrder(item) {
      this.$store.commit("setDeleteItems", {
        id: item.sPurchaseOrderId,
        route: "/api/v1/purchase_order/" + item.sPurchaseOrderId,
        unit: "orden de compra.",
        msgDelete: "Estás a punto de eliminar un elemento",
        msgQuestionDelete: "¿Estás seguro que deseas realizar esta acción?",
      });
    },
    changeTablePagination(e) {
      this.iItemsPerPage = e;
      this.iCurrentPage = 1;
      this.getItems(this.itemStatus);
    },
    backPageContacts() {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    nextPageContacts() {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    mask(price) {
      const nf = new Intl.NumberFormat("en-US");
      let number = nf.format(price);
      return price % 1 === 0 ? (number = number + ".00") : number;
    },
  },
  computed: {
    // return headers from texts
    arrHeaders() {
      return this.texts.headers;
    },
    // set search variable for filter option
    sSearch() {
      return this.$store.state.sSearch;
    },
    startDate() {
      return this.$store.state.dStartDate;
    },
    endDate() {
      return this.$store.state.dEndDate;
    },
    sStatus() {
      return this.$store.state.sStatus;
    },
    sArea() {
      return this.$store.state.sArea;
    },
    token() {
      return "Bearer " + this.$store.state.token;
    },
    refreshTable() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    sArea: function() {
      this.iCurrentPage = 1;
      this.getItems(this.itemStatus);
    },
    sStatus: function() {
      this.iCurrentPage = 1;
      this.getItems(this.itemStatus);
    },
    sSearch: function() {
      this.iCurrentPage = 1;
      this.getItems(this.itemStatus);
    },
    endDate: function() {
      if (this.endDate) {
        this.iCurrentPage = 1;
        this.getItems(this.itemStatus);
      }
    },
    startDate: function() {
      this.iCurrentPage = 1;
      this.getItems(this.itemStatus);
    },
    iCurrentPage: function() {
      this.getItems(this.itemStatus);
    },
    refreshTable: function() {
      this.getItems(this.itemStatus);
    },
  },
  props: {
    // import texts from admin
    texts: Object,
    mdUp: Boolean,
    smUp: Boolean,
  },
};
</script>

<style>
.tableselector.theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: transparent;
}

.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: inherit;
  border-style: solid;
  border-width: thin 0 0 0;
}

.v-text-field > .v-input__control > .v-input__slot:before,
.v-text-field > .v-input__control > .v-input__slot:after {
  bottom: -1px;
  content: "";
  left: 0;
  position: absolute;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
</style>

<style scoped>
.footer-table-dropdown {
  opacity: 0.54;
  color: #475f7b;
  font-size: 12px;
  letter-spacing: 0;
}

.table-contacts thead {
  background-color: #f7f8ff;
}

.table-contacts thead tr th {
  border-bottom: 1px solid #f7f8ff !important;
  color: #47567b !important;
  opacity: 0.87;
  font-size: 13px !important;
  letter-spacing: 0;
  line-height: 15px;
  font-weight: 500;
}

.table-contacts tbody tr td {
  color: #250e62;
  opacity: 0.87;
  font-size: 13px !important;
  letter-spacing: 0;
  border-bottom: 0.5px solid #979797 !important;
}

.footer-table {
  background-color: #fff;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.footer-table p {
  margin-bottom: 0;
  color: #696969;
  font-size: 12px;
  letter-spacing: 0;
}

.footer-table-buttons {
  background-color: #fff !important;
}

.footer-table-buttons i {
  font-size: 24px;
  opacity: 0.54;
  color: #475f7b !important;
}
.actionsIcon {
  font-size: 20px !important;
}
.TableWrapper {
  padding: 15px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 40px #00000019 !important;
  border-radius: 20px !important;
  width: 100%;
  margin-bottom: 25px;
}

.form_labelsStop {
  color: #ff5757;
  font-size: 13px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.form_labelsFinished {
  color: #00b570;
  font-size: 13px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.form_labels {
  color: #ff9d00;
  font-size: 13px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
</style>
