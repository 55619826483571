<template>
  <div>
    <v-dialog
      persistent
      v-model="openDialogDenyOrder"
      width="600"
      style="z-index: 999999999999999999"
    >
      <v-card style="border-radius: 10px; padding: 20px !important">
        <v-icon @click="close" class="dp-icon-modal">mdi-close</v-icon>
        <div class="text-center" style="padding-top: 30px">
          <p class="dp-title-modal">Rechazar cotización</p>
          <p class="dp-text-modal">¿Cuál es el motivo del rechazo?</p>
          <div
            style="
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <div style="width: 300px">
              <v-textarea
                v-model="comentarios"
                outlined
                rows="5"
                class="texts-inputs"
                label="Escribe el motivo del rechazo."
              ></v-textarea>
            </div>
          </div>
          <div style="padding-bottom: 30px">
            <v-btn
              x-large
              color="black"
              width="175"
              outlined
              class="text-Poppins"
              @click="close"
              style="
                height: 50px;
                font-size: 13px;
                font-weight: 600;
                border-radius: 5px;
              "
              >Cancelar
            </v-btn>
            <v-btn
              x-large
              width="175"
              color="primary"
              class="text-Poppins"
              style="
                height: 50px;
                color: black;
                box-shadow: 0px 20px 40px #00000029;
                border-radius: 5px;
                font-size: 13px;
                font-weight: 600;
                margin-left: 50px;
              "
              :disabled="!validateRechazar"
              @click="denyOrder"
              :loading="loadingButtonRechazar"
              >Rechazar
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DenyOrder",
  data() {
    return {
      comentarios: "",
      itemStatus: [],
      loadingButtonRechazar: false,
    };
  },
  props: {
    openDialogDenyOrder: {
      type: Boolean,
      default: false,
    },
    itemDetailsDeny: {
      type: Object,
      default: null,
    },
    rechazarId: {
      type: Array,
      default: null,
    },
  },
  methods: {
    allData() {
      this.getStatus();
    },
    getStatus() {
      // get status
      db.get(`${uri}/api/v1/purchase_order_status`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          var arr = [];

          for (let i = 0; i < resp.data.orderStatus.length; i++) {
            const element = resp.data.orderStatus[i];
            if (element.sName == "Correcciones") {
              arr.push(element);
            }
          }
          this.itemStatus = arr[0];
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    close() {
      this.$emit("close");
    },
    denyOrder() {
      this.loadingButtonRechazar = true;

      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
        },
      };
      const params = {
        sPurchaseOrderStatusId: this.itemStatus.sPurchaseOrderStatusId,
        sComments: this.comentarios,
      };
      db.put(
        `${uri}/api/v1/purchase_order/${this.itemDetailsDeny.sPurchaseOrderId}`,
        params,
        config
      )
        .then((resp) => {
          this.loadingButtonRechazar = false;
          this.comentarios = "";
          this.close();
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.$store.commit("toggle_alert", alert);
          this.$router.push({name:"OCs"})
          // this.$store.commit("refresher", true);
        })
        .catch((err) => {
          this.bLoading = false;
          this.loadingButtonRechazar = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
  },
  computed: {
    validateRechazar() {
      return this.comentarios !== "";
    },
  },
  watch: {
    openDialogDenyOrder: function () {
      if (this.openDialogDenyOrder) {
        this.allData();
      }
    },
  },
};
</script>

<style>
.v-input__slot fieldset {
  background-color: #fafafa;
  border-radius: 10px;
  border: 1px solid #f6f6f6;
}
</style>